import React from "react"
import {Link} from "gatsby"

import styled from 'styled-components'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import TopSection from "../components/sections/TopSection";
import AboutSection from "../components/sections/AboutSection";
import BenefitsSection from "../components/sections/BenefitsSection";
import InTimeSection from "../components/sections/InTimeSection";
import ErnSection from "../components/sections/ErnSection";
import BounceSection from "../components/sections/BounceSection";
import VideoSection from "../components/sections/VideoSection";
import TrainersSection from "../components/sections/TrainersSection";
import WebinarSection from "../components/sections/WebinarsSection";
import FooterSection from "../components/sections/FooterSection";
import TestimonialsSection from "../components/sections/TestimonialsSection";
import SubTopSection from "../components/sections/SubTopSection";

const IndexPage = () => (
    <Layout>
        <SEO title="ШАГИ"/>
        <Wrapper>

            <Content>

                <H1>
                    «Оплата платежной картой в сети Интернет
                </H1>

                <P style={{marginBottom: 20}} >

                    Вы можете оплатить свой заказ платежной картой Visa,
                    Mastercard или МИР в Интернет-магазине. После успешной оплаты Вы получите электронный чек.
                    Информация, указанная на чеке, содержит все данные о проведенной операции оплаты.
                    Гарантии безопасности
                    Данные Вашей платежной карты гарантировано защищены в соответствии со стандартами безопасности PCI DSS.
                    Данные карты вводятся на защищенной банковской платежной странице, передача информации происходит с применением технологии шифрования SSL. Дальнейшая передача информации происходит по закрытым банковским сетям, имеющим наивысший уровень надежности. Для дополнительной аутентификации Держателя карты используется протокол 3D-Secure. Если Эмитент поддерживает данную технологию, Вы будете перенаправлены на его сервер для ввода дополнительных реквизитов платежа".
                </P>

                <P>
                    Оплата доступна в личном кабинете в разделе Настройки->Баланс
                </P>

                <P>
                    <Img src={require('../components/sections/images/pa_1.jpg')}/>
                </P>

                <P>
                    <Img src={require('../components/sections/images/pa_2.jpg')}/>
                </P>



                <H1>
                    Информация о возврате
                </H1>

                <P>
                    Уважаемые Клиенты, информируем Вас о том, что при запросе возврата денежных средств при отказе от
                    покупки,
                    возврат производится исключительно на ту же банковскую карту, с которой была произведена оплата
                </P>


            </Content>


        </Wrapper>

    </Layout>
)

export default IndexPage

const Content = styled.div`
    width: 820px;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    @media(max-width: 820px){
      width: 100%;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
    }
`;

const H1 = styled.div`
    font-weight: bold;
    margin-bottom: 20px;
`;

const P = styled.div`
    
`;

const Img = styled.img`
    width: 100%;
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
`;

const Wrapper = styled.div`
    //font-family: Avenir Next Cyr;
    font-family: "Open Sans",sans-serif;
    overflow-x: hidden !important;
`;
